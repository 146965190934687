// MAIN.JS
jQuery(function($) {

	/**********************************************/

	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
	if ($.fn.mask) {
		$("input[type='text'].phone").mask("(999) 999-9999");
		$("input[type='text'].postalcode").mask("a9a9a9");
	}

	/**********************************************/

	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {
		// image
		$('.image-popup').magnificPopup({type:'image'});
		$('.image-group-popup').magnificPopup({
			delegate: 'a',
			type: 'image'
		});
		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
		$('.iframe-group-popup').magnificPopup({
			delegate: 'a',
			type: 'iframe'
		});
	}

	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

	/**********************************************/

	// OWL.CAROUSEL2
	if ($.fn.owlCarousel) {
		// Etre plus specific dans la classe des carousels dans le cas ou nous avons plusieur instance donc les settings differe!
		$('.owl-carousel').owlCarousel();
	}

	/**********************************************/

	// PARALLAX
	// if ($.fn.parallax) {
	// 	$(".parallax-slow").parallax({
	// 		speed: 0.1
	// 	});
	// 	$(".parallax").parallax({
	// 		speed: 0.3
	// 	});
	// 	$(".parallax-fast").parallax({
	// 		speed: 0.5
	// 	});
	// }

	// PARALLAX
	function parallax() {
		var scroll = $(window).scrollTop();

		if (scroll < 700) {
			translation = "translateX(-100vw)";
			$(".parallax-left-1").css({
				transform: translation,
			});
		}
		if (scroll > 700) {
			translation = "translateX(0vw)";
			$(".parallax-left-1").css({
				transform: translation,
			});
		}
		if (scroll < 900) {
			translation = "translateX(-100vw)";
			$(".parallax-left-2").css({
				transform: translation,
			});
		}
		if (scroll > 900) {
			translation = "translateX(0vw)";
			$(".parallax-left-2").css({
				transform: translation,
			});
		}
		if (scroll < 1100) {
			translation = "translateX(-100vw)";
			$(".parallax-left-3").css({
				transform: translation,
			});
		}
		if (scroll > 1100) {
			translation = "translateX(0vw)";
			$(".parallax-left-3").css({
				transform: translation,
			});
		}
	}
	parallax();
	$( window ).scroll(function() {
		parallax();
	});
	$( window ).on('load',function() {
		parallax();
	});

	/**********************************************/
	function scrollcheck() {
		var scrollTop = $(document).scrollTop();
		if (scrollTop > 0) {
			$('.menu-header').addClass('scrolled');
			$('.body').addClass('scrolled');
		}
		else {
			$('.menu-header').removeClass('scrolled');
			$('.body').removeClass('scrolled');
		}
	}
	$(window).scroll(scrollcheck);
	scrollcheck();

	/**********************************************/

});